@import url(https://fonts.googleapis.com/css2?family=Quicksand);

a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  margin: auto 1rem;
}

a:hover {
  color: #a82d49;
}

img {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.css-0 .css-wlgpcr-MuiButtonBase-root-MuiMenuItem-root {
  justify-content: center !important;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item .css-9vnvu0-MuiCardContent-root {
  text-align: left !important;
}
