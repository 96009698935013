.modal_box {
  height: 80vh;
  width: 80vw;
  padding: 2rem;
  overflow-y: scroll;
  background-color: white;
}

.img_upload {
  font-size: 1rem;
  font-weight: bold;
  font-family: "Quicksand";
}

.img_upload::file-selector-button {
  font-family: "Quicksand";
  font-weight: bold;
  font-size: 1rem;
  background-color: #a82d49;
  color: white;
  border: 0;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
}

.menu_img {
  width: 200px;
}

.uploadImg {
  width: 90%;
  height: 300px;
}

/* .container_card {
  margin: 5px !important;
} */

.img {
  width: 100% !important;
}
