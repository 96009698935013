.borderRadius_img {
  border-radius: 10px;
}
.image_bg_sec {
  background-image: url(/public/images/homebg_3_images.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.image_bg_sec2 {
  background-image: url(/public/images/home_video_section_img.jpg);
  background-size: cover;
  background-position: center;
  height: auto;
  margin-top: 65px;
}

.image_bg_sec3 {
  background-image: url(/public/images/home_bg_4_section.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  height: auto;
  margin-top: 70px;
  position: relative;
  z-index: 1;
}

.image_bg_sec3::before {
  position: absolute;
  top: 0;
  content: "";
  background-color: #000000af;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg_color {
  background-color: #a82d49;
}

@media screen and (max-width: 600px) {
  .bg_color {
    display: none;
  }
}
