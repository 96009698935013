.header_sec {
  border-bottom: 2px solid lightgrey;
  /* padding: 0.5rem 0; */
  height: 80px;
}

.menuList a {
  font-size: 14px !important;
}

.title b {
  font-size: 14px !important;
}

.button b,
.headerImg {
  margin-top: 17px;
}
