.time_input {
  width: 80%;
  margin: 8px auto 10px auto;
  padding: 0.75rem;
  border: 2px solid lightgray;
  border-radius: 10px;
  outline: none;
}

@media screen and (max-width: 950px) {
  .time_input {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .hoursButton {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
