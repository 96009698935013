/* Login Page */

.image_sec {
  background-image: url("/public/images/adminLogin.png");
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.login_form {
  width: 60%;
  margin: auto;
}

.input_box {
  height: 100vh;
}

@media only screen and (max-width: 500px) {
  .input_box {
    height: auto;
    margin: 2rem auto;
  }

  .image_sec {
    height: 50vh;
    width: 100vw;
  }

  .login_form {
    width: 80%;
    margin: auto;
  }
}

/* Restaurants Page */

.admin_table {
  border: 2px solid gray;
  width: 100%;
  text-align: left;
  margin: 2.5rem 0;
  padding: 1rem;
}
