.footer_sec {
  background-color: #a82d49;
  text-align: center;
}

.footer_sec a {
  color: white;
}

.footer_sec a:hover {
  color: white;
}
